//setup floats that work at responsive breakpoints
@media (max-width: $screen-xs-max) {
    .pull-xs-left {
        float: left;
    }
    .pull-xs-right {
        float: right;
    }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .pull-sm-left {
        float: left;
    }
    .pull-sm-right {
        float: right;
    }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    .pull-md-left {
        float: left;
    }
    .pull-md-right {
        float: right;
    }
}

@media (min-width: $screen-lg-min) {
    .pull-lg-left {
        float: left;
    }
    .pull-lg-right {
        float: right;
    }
}

html {
  height: 100%;
}
body {
  background-color: #f8f8f8;
  height: 100%;
}

//type
h1, .h1 {
  font-size: 30px;
}

.no-padding {
    padding: 0;   
}

//forms
.form-control {
  border-radius: 0px;
  padding: 14px;
  height: 54px;
}

.form-control:focus {
  border-color: $brand-aquablue;
  box-shadow: none;
}

.checkbox, .radio {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    display: inline-block;

    label {
      display: block;
      padding: 1em 1em 1em 2.5em;
    }
}

.form-horizontal .radio, .form-horizontal .checkbox {
  padding-top: 0;
}

.form-group.inline-button {
    input.form-control {
        float: left;
    }
    
    button.input-group-addon {
        width: 9rem;
        display: inline-block;
        padding: 19px;
    }
}

input[type="radio"] {
    margin: 4px 0 0;
}

input[type="checkbox"] {
    margin: 5px 0 0;
}

//header
.header {
  background-color: $brand-blue;
  color: #fff;
  padding-top: 3em;
  position: relative;

  h1, a {
    color: #fff;
    margin: 0;
  }

  h1 {
    font-size: 36px;
    margin-top: 1em;
    margin-bottom: 1em;
  }

    @media (max-width: $screen-sm-min) {
    padding-top: 0;
  }

}

.logo-main,   {

  @media only screen and (min-width:  $screen-sm-min  ){
    float: right;
    padding-bottom: 1.5em;
    padding-top: 1.5em;
  }
}

.account-links {

    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    border-bottom: 2px solid lighten($brand-darkblue, 50%);
    padding: 0.5em 0;
    text-align: right;

    ul, li {
       list-style: none;
        margin-left: 0;
        padding-left: 0;
    }

    li {
      display: inline-block;
      margin-right: 10px;
    }


  @media only screen and (max-width:  $screen-sm-min  ){
    border-top: 2px solid #fff;
    padding: 0;
    background-color: lighten($brand-blue, 5%);
    position: static;
    text-align: left;
    margin: 0;

    .container, .row {
      padding: 0;
      margin: 0;
    }

    li {
      display: block;
      margin-right: 0;
    }
    a {
      border-top: 1px solid lighten($brand-blue, 10%);
      display: block;
      padding: 10px 15px;
      line-height: 20px;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .badge {
    background-color: #fff;
    color: $brand-blue;
  }
  ul, li {
    margin-bottom: 0
  }

  a {
    color: #fff;
  }
}

//main nav

.main-nav {

  background-color: $brand-darkblue;
  border-top: 3px solid lighten($brand-blue, 40%);

  .active {
    font-weight: bold;
    position: relative;

    a:before  {
      content: "";
      background-color: #fff;
      display: block;
      height: 5px;
      position: absolute;
      left: 0;
      opacity: 1;
      right: 0;
      bottom: 0;
    }
  }

  @media (max-width: $screen-sm-min) {
      background-color: transparent;
      border-top: none;

      &:hover {
        text-decoration: none;
      }

       .active a:before, li > a:hover:before {
        display: none;
      }
  }
}

.welcome {
    margin-top: 0;   
}

.nav > li > a {
  &:before  {
    transition: all 0.3s ease;
    content: "";
    background-color: lighten($brand-darkblue, 50%);
    display: block;
    height: 5px;
    position: absolute;
    opacity: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &:hover:before {
      opacity: 1;
    }

  &:hover, &:focus {
    background-color: transparent;
  }


}

.navbar-brand {
  height: 65px;
  width: 80%;

  h1 {
    font-size: 28px;
    line-height: 33px;
    text-transform: none;
    float: left;
    margin: 0;
  }
}

.logo-mobile {
  float: left;
  margin-right: 20px;
  width: 60px;
  height: 37px;
}

.navbar-toggle {
  border-left: 1px solid lighten($brand-blue, 60%);
  position: absolute;
  right: 0;
  height: 65px;
  top: 0;
  bottom: 0;
  width: 85px;
  border-radius: 0;
  line-height: 45px;
  margin: 0;
  text-align: center;

  .icon-bar {
    background-color: #fff;
  }

  &:hover {
    background: lighten($brand-blue, 5%);
    text-decoration: none;
  }
}
.navbar-collapse {
  border: none;
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav {
  margin: 0;

//  a {
//    border-top: 1px solid lighten($brand-blue, 10%);
//  }
}
//footer

.footer {
  // background-color: #D2CECE;

  .row {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
  > .container {
    background-color: $brand-blue;
    padding-top: 2em;
    padding-bottom: 2em;
    color: #fff;
      
      a {
          color: #fff;
          text-decoration: underline;
          
          &:hover {
               text-decoration: none;
          }
      }
  }
}

//main
.pg-main {
  background-color: #f8f8f8;
}

.pg-main > .container {
  background-color: #fff;
  padding-top: 2em;
  //padding-bottom: 1em;
}


  .pg-main .container > .row {
    padding: 2.5em 1.5em 3.5em;

    @media (max-width: $screen-sm-min) {
      padding: 1em 0 1em 0;
    }
  }


//row backgrounds
//backgroiund colours for rows
.row-default {
  //background-color: #FBFBFB;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

}

.row-primary {
  background-color: #fff;
  color: #333333;
//  border-top: 5px solid $brand-blue;
//  border-bottom: 5px solid $brand-blue;

}

.row-secondary {
  background-color: $brand-darkblue;
  color: #fff;

}

//login page

.login-outer {
  position: relative;
  padding-top: 3em;
  // display: -ms-flexbox;
  // display: -webkit-flex;
  // display: flex;

  // -ms-flex-align: center;
  // -webkit-align-items: center;
  // -webkit-box-align: center;

  // align-items: center;
  // height: 100%
}

.login {
  border: 1px solid #e4e4e4;
  background-color: #fff;
  //box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);

  .login-header {
    background-color: $brand-blue;
    border-bottom: 1px solid #fff;
    color: #fff;
    padding: 0.5em 2em;

    a {
      color: #fff;
    }
  }

  .login-main {
    padding: 2em;
    background-color: #fff;
  }

  .login-footer {
    border-top: 1px solid $brand-blue;
    padding: 2em;

    p:last-child {
      margin-bottom: 0
    }
  }
}


//panels
.panel {
  border-color: #e4e4e4;
  border-radius: 0;
  //box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
}

.panel-heading {
  border-radius: 0;

}

.panel-title {
  font-size: 1.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.panel-primary {
  border-color: #e4e4e4;

  .panel-heading {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #eee;
    color: $brand-blue;
    position: relative;

    &:before {
      background-color: $brand-blue;
      content: "";
      height: 5px;
      position: absolute;
      left: -1px;
      right: -1px;
      top: 0;
    }

  }
}

//buttons
.btn {
  border-radius: 0;
  transition: all 0.3s ease;
  padding: 14px 20px;
  font-weight: bold;
  // text-transform: uppercase;
}
.btn-primary {
  background-color: $brand-blue;

  &:hover {
    background-color: lighten($brand-blue, 5%);
  }
}

.btn-large {
    padding: 18px 26px;
    font-size: 2rem;
}

.terms {
    .checkbox {
        float: left;   
    }
    
    .supporting-info {
        float: left;
        margin-top: 2rem;
    }
    
    p.supporting-info {
        margin-top: 3rem;
        margin-left: 2rem;   
    }
}

//grid
//uses flexbox to make columns equal height, requires adding to .row
@media only screen and (min-width:  $screen-md-min  ){
  .row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;

    & [class*="col-"] {
      display: flex
    }
  }
}


